import React from 'react'
import './WhatTheySaySection.scss'
import person_1 from 'static/images/o2_solution/bg_person_1.svg'
import person_2 from 'static/images/o2_solution/bg_person_2.svg'
import person_3 from 'static/images/o2_solution/bg_person_3.svg'

const sectionData = [
  {
    url: person_1,
    title: 'OO투자증권 WM 컨설턴트',
    text: (
      <>
        <p>최근에 등록된 고객의 주소 정보를 통해 여러 환경 정보를 얻게 되어 고객의 성향과 관심사를 짐작할 수 있었습니다.</p>
        <p>주소 외에 아무 정보가 없는 콜드 스타트 상황에서 무척 유익했고, 앞으로 상품 판매 정보를 잘 쌓고 교차 분석하면 어떤 고객에 더 신경을 써야할지 파악될 거 같습니다.</p>
      </>
    ),
  },
  {
    url: person_2,
    title: 'OO그룹 렌탈 사업부',
    text: (
      <>
        <p>집값 상승기에는 가구, 가전, 인테리어 등 전반적인 매출이 좋았지만, 부동산 시장이 정체되면서 염려가 생겼습니다.</p>
        <p>재구매와 서비스 해지관련 고객 분석을 통해 세그먼트를 분류하니 현재 고객 뿐 아니라 잠재 고객에 대한 주소 목록을 확보할 수 있어 선제적인 CRM 계획을 세울 수 있게 되었습니다.</p>
      </>
    ),
  },
  {
    url: person_3,
    title: 'OO온라인쇼핑 사업부',
    text: (
      <>
        <p>쿠팡과 11번가 등 오픈마켓에서 상품을 판매하면 주문자 정보가 모두 비식별화되어 버려 고객확보가 어려웠습니다.</p>
        <p>오픈마켓 별로 주소체계도 달라 동일 배송지 확인이 어려웠는데, 주소 표준화를 통해 데이터도 통합하고 자사몰 구축시 연계해 활용할 수 있어 큰 도움이 될 거 같습니다.</p>
      </>
    ),
  },
]

const WhatTheySaySection = () => (
  <div className="what-they-say-section">
    <h3>What they say</h3>
    <div className="what-they-say-section__items">
      {sectionData.map((data, index) => (
        <div key={index} className="what-they-say-section__item">
          <img src={data.url} width={160} height={160} alt="" />
          <div className="what-they-say-section__item_title">{data.title}</div>
          <div className="what-they-say-section__item_text">{data.text}</div>
        </div>
      ))}
    </div>
  </div>
)

export default WhatTheySaySection
