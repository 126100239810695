import React from 'react'
// import HeaderTemplate from 'components/header/HeaderTemplate';
import FooterTemplate from 'components/footer/FooterTemplate'
import './PrivacyTemplate.scss'

const PrivacyTemplate = () => (
  <div className="privacy-template">
    {/*<HeaderTemplate/>*/}
    <div className="privacy-section">
      <h1>개인정보취급방침</h1>
      <div className="privacy">
        <dl>
          <dd>
            주식회사 집펀드(이하 ‘회사’)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보
            처리지침을 수립•공개합니다.
          </dd>
        </dl>
        <dl>
          <dt>1. 개인정보의 처리 목적</dt>
          <dd>
            회사는 다음과 같은 목적으로 이용자 등의 개인정보를 처리합니다.처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에
            따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
          </dd>
          <dd>
            가. 홈페이지 회원 가입 및 관리
            <br />
            가입의사 확인, 회원제 서비스 제공에 따른 본인 식별•인증, 회원자격 유지•관리, 가입 및 가입횟수 제한, 원활한 의사소통 경로의 확보, 고객 문의에 대한 응답, 새로운 정보의 소개 및 고지사항 전달,
            불량회원의 부정이용방지와 비인가 사용방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인,본인확인 및 분쟁 조정을 위한 기록보존, 각종 고지•통지, 불만 등 민원처리 등
          </dd>
          <dd>
            나. 집어드바이저 서비스 기능의 제공
            <br />
            이용자의 연령별/성별/지역별/소득별 통계분석, 통계분석 서비스의 유효성 확인, 부동산 활용 및 대출 사용에 대한 통계추출, 신규 대출 및 부동산 활용방안에 따른 최적 재무설계, 물품배송, 서비스
            제공, 계약서•청구서 발송,콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제•정산, 채권추심 등
          </dd>
          <dd>
            다. 서비스 개선 및 신규 서비스 개발
            <br />
            인구 통계학적 분석, 서비스 방문 및 이용 기록의 분석, 관심사, 부동산 서비스의 필요 등에 기반한 맞춤형 서비스 제공, 개인정보 및 관심에 기반한 추천 서비스 요소를 발굴하고 기존 서비스의 개선에
            이용
          </dd>
          <dd>
            라. 마케팅 및 광고성 정보의 제공
            <br />
            새로운 기능 출시 안내, 이용 방법 및 이용 혜택 안내, 각종 이벤트 참여 기회 및 광고성 정보 게재ᆞ제공, 광고 반응 내역에 대한 통계 자료의 외부 제공 등 법령 등의 이행 및 준수 법령 등에 규정된
            의무의 이행, 법령이나 이용약관에 반하여 이용자에게 피해를 줄 수 있는 잘못된 이용행위의 방지 등
          </dd>
          <dd>
            마. 회사의 개인정보 처리업무
            <br />
            개인정보 처리업무에 따른 처리목적
          </dd>
        </dl>
        <dl>
          <dt>2. 수집하는 개인정보의 항목 및 수집방법</dt>
          <dd>
            가. 수집하는 개인정보의 항목
            <br />
            회사는 회원이 입력한 정보를 기반으로 회원가입, 집어드바이저 서비스의 제공, 원활한 고객상담 등을 위하여 다음과 같은 정보를 수집합니다.
            <div className="subContents">
              1) 필수 항목
              <div className="sub1">
                ● 회원의 아이디(이메일), 비밀번호
                <br />
                ● 회원의 나이,성별, 거주•보유 부동산의 주소, 부동산의 매입시기, 금액, 세금, 대출 등의 매입 관련 정보 및 자가/임대차 정보
                <br />
                ● 이용행태, 접속 IP, 접속로그, 서비스 이용기록, 분석결과등 서비스 이용 과정 상의 생성정보
                <br />
                ● OS 버전, 단말기 정보 등 운영체제 및 하드웨어 환경정보
                <br />
              </div>
              2) 선택 항목
              <div className="sub1">
                ● 회원이 입력한 본인 및 가족의 교통수단 및 주요 거점(직장 및 편의시설 등)에 대한 위치, 방문 빈도 등의 정보, 교육 및 교육기관(초/중/고/대)의 정보, 편의시설 이용정보, 정주 환경 및 시설에
                대한 평가 정보, 가구 정보
                <br />
                ● 대출소개 신청을 하는 경우, 아래의 정보
                <br />
                <div className="sub2">
                  - 신상정보 (주민등록발급일, 주민등록주소, 실거주지증빙여부, 실거주지주소, 가족관계증명서, 동거여부, 기혼여부, 가족관계사항, 병역관련사항, 대학정보등)
                  <br />
                  - 신용정보및대출정보 (신용등급/신용점수/조회건수/대출정보구분,등록일,변동일,기관명,주택담보여부,예적금담보여부, 보증정보, 등록일, 변동일, 보증기관명,
                  보증금액/채무불이행정보/금융질서문란정보, 특수기록정보, 공공기록정보의경우는공통적으로발생하는일자, 해제일자, 기관명, 등록사유, 연체정보, 신용거래의모든사항).
                  <br />
                  - 소득정보 (직군/직종/직군상세/재직증명서확인및발급일/직장명/직장주소/근속기관/소득형태/급여소득)(월별, 연별, 기간별소득금액, 소득정보와관계된주거래 ,일반거래통장의잔고및내역등)
                  <br />
                  - 자산정보 (주택증빙여부, 주택유형, 근저당금액, 임대차계약서-시작, 종료일, 보증금, 월세, 전세차량소유정보에관한사항, 차량유형/차량압류건수,차량저당건수,차량보험가입유무등) <br />
                  - 대출정보에관련된일체사항[개인사업자또는법인대출회원]
                  <br />
                  - 담당자또는대표자의성명및주민등록번호, 아이디, 비밀번호, 닉네임, 휴대전화번호, 유선전화번호, 병역기록, 이동통신사, 자택및직장주소, 생년월일, 성별, 법인명, 사업자등록번호,
                  자본금및설립현황, 법인번호, 업종, 이메일주소, 사업장소재지, 이메일주소서비스이용기록 , 재무현황, 접속로그 , 쿠키 , 접속 IP 정보 , 방문일시 , 서비스이용기록 , 불량이용기록, 가입목적 ,
                  가입경로대출정보, 투자정보신용카드정보, 은행계좌정보, 결제기록등
                  <br />- 신용능력정보 (금융거래등상거래와관련하여발생한개인또는기업의재산, 채무, 소득의총액, 납세실적, “회사”의개황, 사업의내용등일반정보, 재무상태, 재무비율등재무의관한사항,
                  감사인의감사의견및납세실적등비재무에관한사항, 신용정보주체의신용거래능력을판단할수있는능력정보일체)
                </div>
                ● 외부 서비스 계정과 연동할 경우, 외부 서비스에서 제공받는 계정 정보, 서비스 이용 정보 및 기타 회원이 제공에 동의한 개인 정보
              </div>
              3) 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
              <div className="sub1">
                IP Address, 쿠키, 기기정보, 방문 기록, 서비스 이용 기록, 불량 이용 기록 등<br />
              </div>
              4) 유료 서비스 이용 과정에서 결제 등을 위하여 불가피하게 필요한 때에는 다음의 정보가 수집될 수 있습니다.
              <div className="sub1">● 신용카드 정보, 휴대폰번호, 통신사 정보 등과 같이 결제에 필요한 정보</div>
            </div>
          </dd>
          <dd>
            나. 개인정보의 수집방법
            <br />
            회사는 다음과 같은 방법으로 개인정보를 수집합니다.
            <div className="subContents">
              1) 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집
              <br />
              2) 회원의 선택에 따라 네이버, 페이스북, 구글 등의 아이디를 이용하여 로그인하는 회원의 경우 해당 회사에서 회원에게 동의를 받은 후 제공받아 수집
              <br />
              3) 회원이 정보 조회를 위해 입력하는 경우 그에 따라 수집
              <br />
              4) 그 외 제휴된 외부 기업이나 단체로부터 제공
              <br />
              5) 기기정보, 서비스 이용 기록 등 생성정보는 서비스 이용과정에서 자동으로 수집
            </div>
          </dd>
        </dl>
        <dl>
          <dt>3. 개인정보의 처리 및 보유기간</dt>
          <dd>
            회사는 회원 가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 처리 및 보유합니다. 회원탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및
            이용 목적이 달성되거나 처리 및 보유 기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다. 단, 회사는 통계 및 분석을 위하여 비식별화 처리된 정보를 보관하고,관계법령의 규정에 의하여
            보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
            <br />
            <div className="subContents">
              1) "회사"는 고객이 탈퇴하거나, 고객을 제명하는 경우 권리남용, 악용방지, 권리침해/명예훼손 분쟁 및 수사협조 의뢰에 대비하여 이용계약 해지일로부터 3년 동안 개인정보를 보존합니다.
              <br />
              개인정보 : 이름, ID, 비밀번호, 휴대전화번호, 이동통신사, e-mail주소, 자택주소, 직장주소, 생년월일, 성별, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 가입목적, 가입경로 등<br />
              2) 회사는 다음의 정보에 대해서 명시한 기간 동안 보존합니다.
              <br />
              <div className="sub1">
                ① 계약 또는 청약철회 등에 관한 기록
                <div className="sub2">
                  보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률
                  <br />
                  보존기간 : 5년
                </div>
                ② 대금결제 및 재화 등의 공급에 관한 기록
                <div className="sub2">
                  보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률
                  <br />
                  보존기간 : 5년
                </div>
                ③ 소비자의 불만 또는 분쟁처리에 관한 기록
                <div className="sub2">
                  보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률
                  <br />
                  보존기간 : 3년
                </div>
                ④ 표시/광고에 관한 기록
                <div className="sub2">
                  보존근거 : 전자상거래 등에서 소비자 보호에 관한 법률
                  <br />
                  보존기간 : 6개월
                </div>
                ⑤ 신용정보의 수집/처리 및 이용 등에 관한 기록
                <div className="sub2">
                  보존근거 :신용정보의 이용 및 보호에 관한 법률
                  <br />
                  보존기간 : 3년
                </div>
                ⑥ 기타 고객의 동의를 받은 경우: 동의를 받은 기간까지
              </div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>4. 개인정보의 파기</dt>
          <dd>
            회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.다만, 회사는 불량 회원의 부정한 이용의 재발을 방지하기 위해서
            이용계약 해지일로부터 1년간 해당 회원의 아이디를 보유할 수 있습니다.정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
            계속 보존하여야 하는 경우 또는 회원이 정보통신서비스를 1년의 기간 동안 이용하지 아니하는 경우에는, 비식별화 처리된정보 외의 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를
            달리하여 보존합니다.
            <div className="subContents">
              1) 파기절차
              <br />
              회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
              <br />
              2) 파기방법
              <br />
              회사는 전자적 파일 형태로 기록•저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록•저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            </div>
          </dd>
        </dl>
        <dl>
          <dt>5. 개인정보의 제3자 제공</dt>
          <dd>
            회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, (1) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우 및
            (2) 통계처리, 학술연구, 시장조사 등을 위하여 필요한 경우로서 특정한 개인을 식별할 수 없는 형태로 개인정보를 제공하는 경우에만 아래의 기관에 개인정보를 제공합니다.
            <div className="subContents">
               개인정보를 제공받는 자: 주식회사 집펀드대부
              <br />
               개인정보 이용목적: 대출 소개 업무 처리
              <br /> 제공하는 개인정보 항목
              <div className="sub1">
                - 신상정보 (주민등록발급일, 주민등록주소, 실거주지증빙여부, 실거주지주소, 가족관계증명서, 동거여부, 기혼여부, 가족관계사항, 병역관련사항, 대학정보등)
                <br />
                - 신용정보및대출정보 (신용등급/신용점수/조회건수/대출정보구분,등록일,변동일,기관명,주택담보여부,예적금담보여부, 보증정보, 등록일, 변동일, 보증기관명,
                보증금액/채무불이행정보/금융질서문란정보, 특수기록정보, 공공기록정보의경우는공통적으로발생하는일자, 해제일자, 기관명, 등록사유, 연체정보, 신용거래의모든사항).
                <br />
                - 소득정보 (직군/직종/직군상세/재직증명서확인및발급일/직장명/직장주소/근속기관/소득형태/급여소득)(월별, 연별, 기간별소득금액, 소득정보와관계된주거래 ,일반거래통장의잔고및내역등)
                <br />
                - 자산정보 (주택증빙여부, 주택유형, 근저당금액, 임대차계약서-시작, 종료일, 보증금, 월세, 전세차량소유정보에관한사항, 차량유형/차량압류건수,차량저당건수,차량보험가입유무등) <br />
                - 대출정보에관련된일체사항[개인사업자또는법인대출회원]
                <br />
                - 담당자또는대표자의성명및주민등록번호, 아이디, 비밀번호, 닉네임, 휴대전화번호, 유선전화번호, 병역기록, 이동통신사, 자택및직장주소, 생년월일, 성별, 법인명, 사업자등록번호,
                자본금및설립현황, 법인번호, 업종, 이메일주소, 사업장소재지, 이메일주소서비스이용기록 , 재무현황, 접속로그 , 쿠키 , 접속 IP 정보 , 방문일시 , 서비스이용기록 , 불량이용기록, 가입목적 ,
                가입경로대출정보, 투자정보신용카드정보, 은행계좌정보, 결제기록등
                <br />- 신용능력정보 (금융거래등상거래와관련하여발생한개인또는기업의재산, 채무, 소득의총액, 납세실적, “회사”의개황, 사업의내용등일반정보, 재무상태, 재무비율등재무의관한사항,
                감사인의감사의견및납세실적등비재무에관한사항, 신용정보주체의신용거래능력을판단할수있는능력정보일체)
              </div>
               제공받는 자의 보유•이용 기간: 회원 탈퇴 시까지
            </div>
          </dd>
        </dl>
        <dl>
          <dt>6. 정보주체의 권리•의무 및 행사방법</dt>
          <dd>
            정보주체는회사에 대해 언제든지 다음의 개인정보 보호 관련 권리를 행사할 수 있습니다.
            <div className="subContents">
               개인정보 열람요구
              <br />
               오류 등이 있을 경우 정정 요구
              <br />
               삭제 요구
              <br /> 처리정지 요구
            </div>
          </dd>
        </dl>
        <dl>
          <dt>7.개인정보의 안전성 확보조치</dt>
          <dd>
            회사는 개인정보의 안전선 확보를 위해 다음과 같은 조치를 취하고 있습니다.
            <div className="subContents">
              1) 개인정보 취급 직원의 최소화 및 교육
              <div className="sub1">개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</div>
              2) 정기적인 자체 감사 실시
              <div className="sub1">개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</div>
              3) 내부관리계획의 수립 및 시행
              <div className="sub1">개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</div>
              4) 개인정보의 암호화
              <div className="sub1">
                이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
                보안기능을 사용하고 있습니다.
              </div>
              5) 해킹 등에 대비한 기술적 대책
              <div className="sub1">
                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신•점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고
                기술적/물리적으로 감시 및 차단하고 있습니다.
              </div>
              6) 개인정보에 대한 접근 제한
              <div className="sub1">
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
                접근을 통제하고 있습니다.
              </div>
              7) 접속기록의 보관 및 위변조 방지
              <div className="sub1">개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.</div>
              8) 문서보안을 위한 잠금장치 사용
              <div className="sub1">개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</div>
              9) 비인가자에 대한 출입 통제
              <div className="sub1">개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>7.개인정보 보호책임자</dt>
          <dd>
            "회사"는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
            <br />
            <div className="subContents">
              1) 개인정보보호책임자
              <div className="sub1">
                성명 : 남성태
                <br />
                직책 : 대표이사
                <br />
                전화번호 : 02-553-6369
                <br />
                회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 고객의
                문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
              </div>
              2) 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
              <div className="sub1">
                개인정보분쟁조정위원회 ( www.kopico.go.kr / 1833-6972)
                <br />
                개인정보침해신고센터( privacy.kisa.or.kr / 118)
                <br />
                대검찰청 사이버범죄수사단 (www.spo.go.kr / 02-3480-3573)
                <br />
                경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 182)
              </div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>8.개인정보 처리방침 변경</dt>
          <dd>
            이 개인정보처리방침은 시행일(2017. 9. 1.)로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
          </dd>
        </dl>
      </div>
    </div>
    <FooterTemplate />
  </div>
)

export default PrivacyTemplate
