import React from 'react'
import InqueryButton from '../InqueryButton/InqueryButton'
import './BannerSection.scss'

const BannerSection = () => (
  <div className="banner-section">
    <div className="banner-section__content">
      <h2>
        모든 고객은 오프라인에 존재합니다.
        <br />
        그들의 데이터는 전략이 됩니다.
      </h2>
      <p>
        AI시대, 고객의 주소를 데이터로 활용하는
        <br />
        디지털 전환 솔루션
      </p>
      <p className="banner-section__inquery">
        <InqueryButton />
      </p>
    </div>
  </div>
)

export default BannerSection
