import React from 'react'
import './StepSection.scss'
import TwoColumnContent from '../TwoColumnContent/TwoColumnContent'
import step_visual_1 from 'static/images/o2_solution/step_visual_1@2x.png'
import step_visual_2 from 'static/images/o2_solution/step_visual_2@2x.png'
import step_visual_3 from 'static/images/o2_solution/step_visual_3@2x.png'
import step_arrow_down from 'static/images/o2_solution/bg_step_arrow_down.svg'

const PointLine = ({ isEndCircleDown }) => {
  const cx = `step-section-point-line__${isEndCircleDown ? 'down' : 'up'}`
  const pointLine = <div className="step-section-point-line__point"></div>
  return (
    <div className={`step-section-point-line ${cx}`}>
      {!isEndCircleDown && pointLine}
      <div className="step-section-point-line__line"></div>
      {isEndCircleDown && pointLine}
    </div>
  )
}

const stepVisual1 = (
  <div className="step-section__visual-1">
    <img
      src={step_visual_1}
      alt=""
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: 594,
      }}
    />
    <img src={step_arrow_down} width={18} height={42} className="step-section__visual-1-arrow" alt="" />
    <p className="step-section__visual-1-text">
      서울특별시 강남구 압구정로 11길 17 21동 203호
      <br />
      (압구정동, 미성아파트)
    </p>
  </div>
)

const stepVisual2 = (
  <div className="step-section__visual-2">
    <img
      src={step_visual_2}
      alt=""
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: 540,
      }}
    />
  </div>
)

const offlineData = ['인구구성', '주택거주년수', '가구주 정보(성별/연령/거주형태)', '대중교통이용횟수/인원', '평균소득/ 생활비 지출액/ 소비특성', '건강행태']
const onlineData = [
  <>
    가입 정보 / 접속 정보 (횟수, 빈도)
    <br />/ 활동 정보 (이용내역) / 구매 정보 (결제 내역)
  </>,
]

const stepVisual3 = (
  <div className="step-section__visual-3">
    <div className="step-section-info-box step-section-info-box__highlight">
      <strong>고객 거주지의 오프라인 환경 정보 통계</strong>
      <ul>
        {offlineData.map((item, index) => (
          <li key={index}>
            {item}
            {index === offlineData.length - 1 && (
              <>
                &nbsp;...&nbsp;<span className="step-section-info-box__etc">외 다수</span>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
    <PointLine isEndCircleDown={true} />
    <img
      src={step_visual_3}
      alt=""
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: 340,
      }}
    />
    <PointLine isEndCircleDown={false} />
    <div className="step-section-info-box">
      <strong>온라인 행동 정보</strong>
      <ul>
        {onlineData.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  </div>
)

const sectionData = [
  {
    title: '주소 표준화',
    descriptionContent: (
      <>
        <p>
          방대한 데이터를 연결하고 결측 자료를
          <span className="br"></span>
          처리해야 하는 수많은 과정은 프로젝트를
          <span className="br"></span>
          지연시키고 비용과 리스크가 증가 합니다.
        </p>
        <p>
          오투 솔루션은 고객의 주소를 표준화 시켜
          <span className="br"></span>
          데이터로 활용할 수 있게 합니다.
        </p>
      </>
    ),
    tagContent: '#DX #디지털전환 #CRM #위치기반',
    visualContent: stepVisual1,
  },
  {
    title: '추가 데이터 연결',
    descriptionContent: (
      <>
        <p>
          주소 변환을 통해 주소지에 기반한
          <span className="br"></span>
          다양한 데이터를 연결할 수 있습니다.
        </p>
        <p>
          고객의 온라인 행동 데이터를
          <span className="br"></span>
          더욱 입체적으로 이해할 수 있고,
          <span className="br"></span>
          기계학습, CRM, 마케팅 등 AI관련 기술을
          <span className="br"></span>
          고도화 할 수 있습니다.
        </p>
      </>
    ),
    tagContent: '#CDP #세그멘테이션 #타겟팅 #ML',
    visualContent: stepVisual2,
  },
  {
    title: '상관관계 분석',
    descriptionContent: (
      <>
        <p>
          기존의 변수들과의 상관관계를 통해
          <span className="br"></span>
          궁극적으로 고객에 대한 예측가능성이 올라가고
          <span className="br"></span>
          데이터에 기반한 마케팅과 상품개발,
          <span className="br"></span>
          사업 전략 수립이 가능해집니다.
        </p>
      </>
    ),
    tagContent: (
      <>
        #개인화 #RFM #DigitalMarketing
        <br />
        #CDD #CSS
      </>
    ),
    visualContent: stepVisual3,
  },
]

const StepSection = () => (
  <div className="step-section">
    {sectionData.map((data, index) => {
      const textContent = (
        <div className="step-section__text-content">
          <span className="step-section__step">{index + 1}단계</span>
          <div className="step-section__title">{data.title}</div>
          <div className="step-section__description">{data.descriptionContent}</div>
          <div className="step-section__tag">{data.tagContent}</div>
        </div>
      )
      const visualContent = <div className="step-section__visual-content">{data.visualContent}</div>

      return (
        <div key={index} className={`step-section__content--${index + 1}`}>
          <TwoColumnContent leftContent={textContent} rightContent={visualContent} alignItems="start" reverseOrder={index % 2 === 1} />
        </div>
      )
    })}
  </div>
)

export default StepSection
