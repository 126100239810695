import React from 'react'
import './BannerSection.scss'
import storyImg from 'static/images/introduction/story-img@2x.png'
import postImg from 'static/images/introduction/post-img@2x.png'

const BannerSection = () => {
  return (
    <div className="banner-section" id="banners">
      <div className="banner-section__story">
        <div className="banner-info">
          <p className="banner-info__text">
            집펀드와 함께하는 <span className="delimiter"></span>구성원들 이야기
          </p>
          <a className="banner-info__link" href={'https://zfmanagement.notion.site/e873e0eae1614f97ab7e9a5a83b1c316'} target={'_blank'} rel="noopener noreferrer">
            <button>집펀드 스토리</button>
          </a>
        </div>
        <img src={storyImg} alt="storyImg" />
      </div>
      <div className="banner-section__post">
        <div className="banner-info">
          <p className="banner-info__text">부동산 금융 비서 서비스, 집비서</p>
          <a className="banner-info__link" href={'https://blog.naver.com/zipadvisor'} target={'_blank'} rel="noopener noreferrer">
            <button>네이버 블로그</button>
          </a>
        </div>
        <img src={postImg} alt="storyImg" />
      </div>
    </div>
  )
}

export default BannerSection
