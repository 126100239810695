import React from 'react'
// import HeaderTemplate from 'components/header/HeaderTemplate';
import FooterTemplate from 'components/footer/FooterTemplate'
import './PolicyTemplate.scss'

const PolicyTemplate = () => (
  <div className="policy-template">
    {/*<HeaderTemplate/>*/}
    <div className="policy-section">
      <h1>이용 약관</h1>
      <div className="policy">
        <dl>
          <dt>
            <strong>제 1 조</strong> 약관의 적용
          </dt>
          <dd>
            이 약관은 ㈜집펀드(이하 "회사" 또는 "집펀드"라함)가 운영하는 인터넷 웹사이트 “zipadvisor.co”(이하 "사이트"라함)에서 제공하는 모든 웹서비스(이하 "서비스"라함)를 이용함에 있어 "회사"와 회원
            간의권리, 의무 및 책임 사항을 규정함을 목적으로 합니다. "회사"는 시스템에 관한 제반기술과 운영에 대한 모든 권한을 갖고 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 2 조</strong> 용어의 정의
          </dt>
          <dd>
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <br />
            <br />
            1. "사이트"란 회사가 서비스 또는 용역을 회원에게 제공하기위하여 컴퓨터 등 정보통신설비를 이용하여 제공할 수 있도록 설정한 가상의 영업장 또는 서비스 공간을 말하며, 회원계정(ID 및
            PASSWORD)을 이용하여 서비스를 제공받을 수 있는 인터넷 웹사이트를 말합니다. 아울러 사이트를 운영하는 사업자의 의미로도 사용합니다.
            <br />
            2. "서비스"는 "회사"의 홈페이지 및 "회사"가 직접 운영하는 웹사이트 등에서 제공하는 온라인 상의 모든 서비스를 말합니다.
            <br />
            3. "회원"은 본 약관에 동의하고 "회사"가 운영하는 사이트에서 서비스를 제공받는 자(이하 "회원"이라함)를 말합니다. "회사"의 정책에 의하여 회원의 등급을 구분하여 서비스 이용범위나 혜택 등을
            다르게 적용할 수 있습니다.
            <br />
            4. "ID"는 회원으로 로그인하기위하여 "사이트"에 등록한 E-MAIL을 말합니다.
            <br />
            5. "PASSWORD"는 회원의 비밀보호 및 회원본인임을 확인하고 서비스에 제공되는 각종 정보의 보안을 위해 회원이 직접 설정하며 "회사"가 승인하는 6자리이상 20자리이하의 영문과 숫자, 특수문자의
            혼합으로 표기한 암호문자를말합니다.
            <br />
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 3 조</strong> 약관의 명시와 해석
          </dt>
          <dd>
            1. “회사”는 이 약관의 내용과 상호, 영업소재지, 대표자의성명, 사업자등록번호, 연락처 등을 ”회원”이 알 수 있도록 “서비스” 초기화면에 게시하거나 기타의 방법으로 ”회원”에게 공지합니다.
            <br />
            2. “회원”이 “회사”와 개별 계약을 체결하여 “서비스”를 이용하는 경우 “회사”는 개별서비스에 대한 이용약관 또는 운영정책 등(이하 "운영정책등")을 둘 수 있으며, 해당내용이 본약관과 상충되는 경우
            개별서비스에 대한 운영정책 등이 우선합니다.
            <br />
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 4 조</strong> 서비스 이용계약의 성립 등
          </dt>
          <dd>
            1. 서비스 이용계약은 "회원"이 되고자 하는 자가 본약관에 동의하고 정해진 가입양식에 회원정보(성명, ID, PASSWORD, 기타 "회사"가 필요하다고 인정하는 사항 등)를 직접 기입하여 회원신청을 하면
            "회사"가 이에 대해 승낙함으로써 성립됩니다.
            <br />
            2. "회원"에 가입하는 자는 "회사"가 요구하는 개인정보를 정확하게 제공하여야 합니다.
            <br />
            3. "회사"는 ID, PASSWORD를 접수 받아 이를 관리합니다.
            <br />
            4. 이 약관은 "회사"의 사이트에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.
            <br />
            5. "회원"은 약관의 변경에 대하여 주의의무를 다하여야하며, 변경된 약관의 부지로 인한 피해는 회사가 책임지지않습니다. 이용고객이 서비스 이용을 신청하는 것은 약관에 따라 신청양식에 기재된
            회원정보의 수집/이용 및 "회사"가 제정한 "개인정보보호정책" 등 각종 정책과 서비스 이용과정에서 준수할 필요가 있어 "회사"가 수시로 공지하는 사항에 대해 동의한 것으로 간주됩니다.
            <br />
            6. 타인의 개인정보를 도용하여 허위 가입한 회원은 회사가 제공하는 "서비스"를 이용할 수 없으며 이에 따른 민사, 형사 상의 모든 책임은 가입한 회원에게 있습니다.
            <br />
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 5 조</strong> 회원가입
          </dt>
          <dd>
            1. 사이트가 정한 가입양식에 따라 회원정보를 기입함으로써 회원가입을 신청합니다.
            <br />
            2. "회사"는 특정 서비스를 제공하기위해 회원에게 별도 또는 추가적인 가입절차를 요청할 수 있으며, 이러한 특정서비스를 이용할 경우 해당서비스에 대한 이용약관, 규정 또는 세칙 등이 본 약관보다
            우선 적용됩니다.
            <br />
            3. "회사"는 회원이 사이트 및 서비스를 쉽게 이용할 수 있도록 회원의 아이디를 포함한 회원제를 관리하거나 사이트 또는 서비스를 개선, 변경할 수 있습니다.
            <br />
            4. 이용 고객은 회원가입 시에 "회사"가 서비스를 제공함에 있어서 필요한 정보를 제공해야하며, "회사"가 특별히 요청할 경우 서류를 제출해야 합니다.
            <br />
            5. "회원"이 사실과 다른 정보 또는 허위정보를 기입하거나 추후 그러한 정보임이 밝혀질 경우 운영자의 권한으로 서비스 이용을 일시정지하거나 영구정지 및 이용계약을 해지할 수 있습니다. 이로
            인하여 회사 또는 제3자에게 발생한 손해는 해당회원이 책임을 집니다. 다만, 회사의 고의나 과실에 의하여 손해가 발생한 경우에는 회사가 손해를 부담합니다.
            <br />
            6. "회사"는 회원이 가입 신청한 경우 법령에 따라 이용가능한 신용정보업자 또는 신용정보집 중 기관을 통하여 본인 여부를 확인할 수 있으며, "회사"는 실명 확인절차를 취할 수 없는
            이용신청에대해서는 신청자에게 증빙자료를 요청할 수 있습니다.
            <br />
            7. "회사"는 다음과 같은 사유가 있는 경우, 가입신청에 대한 승낙을 유보할 수 있습니다. 이 경우, 회사는 가입신청자에게 승낙 유보의 사유, 승낙 가능시기 또는 승낙에 필요한 추가 요청정보 내지
            자료 등 기타 승낙 유보와 관련된 사항을 해당 서비스 화면에 게시하거나 기타 방법을 통하여 회원에게 통지합니다.
            <br />
            <div className="subContents">
              1. 가입 신청자가 이 약관 제9조에 의하여 이전에 회원자격을 상실한 적이 있는 경우
              <br />
              2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
              <br />
              3. 기타, 회원으로 등록시 “사이트”의 운영상 현저히 지장이 있다고 판단되는 경우
            </div>
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 6 조</strong> 회원정보의 수집과 보호
          </dt>
          <dd>
            1. "회사"는 이용계약을 위하여 "회원"이 제공한 정보 외에도 수집목적 또는 이용목적을 밝혀 "회원"으로부터 필요한 정보를 수집할 수 있습니다. 이 경우, 회사는 회원으로부터 정보수집에 대한 동의를
            받습니다.
            <br />
            2. "회사"가 정보수집을 위하여 "회원"의 동의를 받는 경우, "회사"는 수집하는 개인정보의 항목 및 수집방법, 수집목적 및 이용목적, 개인정보의 보유 및 이용기간, 제3자에 대한 정보제공사항
            (제공받는 자, 제공받는 자의 이용목적, 제공정보의 항목, 보유 및 이용기간) 등을 개인정보 처리방침으로 미리 명시하거나 고지합니다. 회원은 정보제공에 동의하더라도 언제든지 그 동의를 철회할 수
            있습니다.
            <br />
            3. "회원"은 "회사"에게 정보를 제공하는 경우 사실대로 제공하여야 합니다.
            <br />
            4. "회사"는 회원의 개인정보보호를 위하여 관리자를 최소한으로 한정하며, 회사의 고의 또는 과실로 인하여 회원정보가 분실, 도난, 유출, 변조된 경우에는 그로 인한 회원의 손해에 대하여 모든
            책임을 부담합니다.
            <br />
            5. “회사”는 “회원”의 귀책 사유로 인하여 노출된 “회원”의 계정정보를 포함한 모든 정보에 대해서는 일체의 책임을 지지않습니다.
            <br />
            6. "회사"는 관련 법령이 정하는 바에 따라서 회원정보를 포함한 개인정보를 보호하기 위하여 노력합니다. "회원"의 개인정보보호에 관해서는 관련 법령 및 "회사"가 정하고 별도로 게시하는
            "개인정보처리방침"에 정한 바에 준합니다.
            <br />
            7. 이용계약이 종료된 경우, 회사는 당해회원의 정보를 파기하는 것을 원칙으로 합니다. 다만, 아래의 경우에는 회원정보를 보관합니다. 이 경우 회사는 보관하고 있는 회원정보를 그 보관의
            목적으로만이 용합니다.
            <br />
            <div className="subContents">
              a. 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간동안 회원정보를 보관합니다.
              <br />
              b. 회원의 탈퇴 신청 등으로 이용계약이 종료된 경우, 회사는 투자자보호 및 본 약관이 정한 제한을 의도적으로 회피하기 위한 임의 해지 방지를 위하여 상당하다고 인정되는 경우에 한하여 이용계약
              종료 후 1년간 ID, 성명 또는 상호, 연락처, 거래상세내역, 약관 위반행위 자료 등 최소한의 필요 정보를 보관합니다.
              <br />
              c. 회사가 이용계약을 해지하거나 회사로부터 회원 자격정지 조치를 받은 회원에 대해서는 재가입에 대한 승낙거부사유가 존재하는지 여부를 확인하기위한 목적으로 이용계약 종료 후 1년간 ID, 성명
              또는 상호, 연락처, 주소, 해지와 회원 자격정지와 관련된 정보 등 최소한의 필요 정보를 보관합니다.
              <br />
              d. 기타 정보수집에 관한 동의를 받을 때 보유기간을 명시한 경우에는 그 보유기간까지 회원정보를 보관합니다.
              <br />
            </div>
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 7 조</strong> 회사의 의무
          </dt>
          <dd>
            1. “회사”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 “회원”에게 서비스를 제공하기 위해 최선을 다합니다.
            <br />
            2. “회사”는 “서비스”에 따른 정보의 정확성, 신뢰성 및 “회원”이 “서비스”를 근거로 하는 거래에 있어서 어떠한 보증도 제공하지 않습니다. “서비스”를 근거로 하는 거래행위에서 발생하는 문제 및
            손실에 대해서 “회사”는 민형사상 책임 등 일체의 책임을 부담하지않으며, 거래의 위험과 책임은 “회원”이 부담합니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 8 조</strong> 회원의 의무
          </dt>
          <dd>
            1. 회원은 서비스를 이용할 때 아래의 행위를 하지않아야 합니다.
            <div className="subContents">
              a. 가입신청 시 개인정보의 허위사실을 기재하는 행위
              <br />
              b. 다른회원의 ID 및 PASSWORD를 부정하게 이용하는 행위
              <br />
              c.서비스를 이용하여 얻은 정보를 회원의 개인적인 이용 외에 복사, 가공, 번역, 2차적저작 등을 통하여 복제, 공연, 방송, 전시, 배포, 출판 등에 이용하거나 제3자에게 제공하는 행위
              <br />
              d. 타인의 명예를 손상시키거나 불이익을 주는 행위
              <br />
              e. "회사"의 저작권, 제3자의 저작권 등 기타권리를 침해하는 행위
              <br />
              f. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위
              <br />
              g. 범죄와 결부된다고 객관적으로 인정되는 행위
              <br />
              h. "서비스"와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터바이러스 감염자료를 등록 또는 유포하는 행위
              <br />
              i. "서비스"의 안정적 운영을 방해할 수 있는 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 전송하는 행위
              <br />
              j. 기타 관계 법령에 위배되는 행위
              <br />
            </div>
            2. ID와 PASSWORD 관리에 관한 일체의 책임은 "회원" 본인에게 있습니다. "회원"에게 부여된 ID와 PASSWORD를 제3자에게 대여 또는 양도하거나 이와 유사한 행위를 하여서는 안되며, ID와 PASSWORD의
            관리소홀, 부정이용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다.
            <br />
            3. "회원"은 자신의 ID가 부정하게 이용된 경우, 즉시 자신의 PASSWORD를 변경하고 그 사실을 "회사"에 통보하여야 합니다.
            <br />
            4. "회원"은 본인의 신상관련 사항이 변경되었을 때는 "사이트"를 통하여 수정하거나 유선으로 지체없이 "회사"에 통보하여야 합니다.
            <br />
            5. "회원"은 "회사"의 사전승낙없이 "서비스"를 이용하여 영업활동을 할 수 없으며, 그 영업활동으로 인한 결과에 대하여 "회사"는 책임을 지지아니 합니다.
            <br />
            6. "회원"은 이 약관 및 관계법령에서 규정한 사항과 서비스 이용 안내 또는 주의사항을 성실히 준수하여야합니다.
            <br />
            7. "회원"은 내용별로 "회사"가 서비스 공지사항에 게시하거나 별도 공지한 이용제한사항을 준수하여야 합니다.
            <br />
            8. "회원"은 "회사"의 사전동의없이 "서비스"의 이용권한, 기타이용계약 상 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.
            <br />
            9. "회원"은 반드시 본인의 실명으로 등록하여야하며, 타인의 명의를 도용하는 경우 일체의 보호를 받을 수 없고, 관계법령에 의하여 처벌받을 수 있습니다.
            <br />
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 9 조</strong> 서비스 이용 권리의 양도 등
          </dt>
          <dd>
            1. "회사"의 사전동의 없이 "서비스"의 이용권한, 기타 이용계약상지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
            <br />
            2. "회사"는 보안 및 ID 정책, "서비스"의 원활한 제공 등과 같은 이유로 제 15 조의 방법으로 고지를 통하여 "회원"의 ID 변경을 요구하거나 변경할 수 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 10 조</strong> 계약해지 및 이용제한
          </dt>
          <dd>
            1. "회사"는 "서비스" 이용을 신청한 고객에 대하여 접수순서에 따라 "서비스" 이용을 승낙합니다. "회원"이 서비스 이용계약을 해지하고자 할 경우에는 본인이 "회사" 홈페이지 또는 "회사"가 정한
            별도의 방법을 이용하여 "회사"에 해지신청을 하여야 합니다. 이 경우 "회사"는 소정의 해지절차를 거쳐 해지를 요청한 "회원"의 정보를 삭제합니다.
            <br />
            2. "회사"는 서비스 이용 신청이 다음 각호에 해당하는 것으로 판단되거나 서비스 이용 신청 이후의 이용 상황이 다음 각호의 하나로 판단되는 경우에는 이용승낙을 하지아니하거나 또는 이용을
            제한하고 탈퇴시킬 수 있습니다.
            <br />
            <div className="subContents">
              a. ID와 PASSWORD 등 회원 고유정보를 타인에게 누설하거나 타인의 ID 및 PASSWORD를 도용한 경우
              <br />
              b. 서비스 운영을 고의로 방해한 경우
              <br />
              c. 가입한 성명이 실명이 아닌 경우
              <br />
              d. 동일 이용자가 다른 ID로 이중등록을 한 경우
              <br />
              e. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
              <br />
              f. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행하는 경우
              <br />
              g. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우
              <br />
              h. 서비스의 안정적 운영을 방해할 목적으로 정보를 전송하거나 광고성 정보를 전송하는 경우
              <br />
              i. 통신설비의 오동작이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우
              <br />
              j. "회사", 타회원 또는 제3자의 지적재산권을 침해하는 경우
              <br />
              k. "회사"의 서비스 정보를 이용하여 얻은 정보를 "회사"의 사전승낙없이 복제 또는 유통시키거나 상업적으로 이용하는 경우
              <br />
              l. 회원이 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 경우
              <br />
              m. 서비스 이용약관을 포함하여 기타 "회사"가 정한 이용조건 및 관계법령을 위반해 더이상의 서비스 제공이 어렵다고 판단되는 경우
              <br />
              n. 스토킹(stalking) 등 다른 이용자를 괴롭히는 행위를 하는 경우
              <br />
              o. "회사"가 신청 양식에서 정한 회원정보가 미비된 경우
              <br />
              p. 범죄행위를 목적으로 서비스 신청을 한 것으로 판단되는 경우
              <br />
              q. 본 약관에서 예정하고 있는 거래 이외의 영리를 추구할 목적으로 서비스 가입을 한 것으로 판단되는 경우
              <br />
              r. 기탈퇴한 고객으로서 관리자의 판단으로 재가입이 웹사이트의 운영에 저해가 된다고 판단되는 경우
              <br />
              s. 주관적인 글의 게재로 인하여 타회원에게 부정적 영향을 끼칠 가능성이 있는 경우
              <br />
              t. 기타 이 약관 상의 제반의무를 위반하는 경우
              <br />
              u. 바이러스의 유포가능성이나 이에 대한 의심이 있는 경우
              <br />
            </div>
            3. 본조제 2 항에 해당하는 행위를 한 "회원"은 이로 인해 "회사" 또는 다른 회원에게 발생한 손해를 배상할 책임이 있습니다.
            <br />
            4. 본조제 2 항의 "회사" 조치에 대하여 "회원"은 "회사"가 정한 절차에 따라 이의 신청을 할 수 있고, 이의가 정당하다고 인정하는 경우 "회사"는 해당 서비스의 이용을 재개합니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 11 조</strong> 전자메일에 대한 회원의 의무와 책임
          </dt>
          <dd>
            1. "회사"는 "회원"의 전자메일 내용을 편집하거나 감시하지 않습니다.
            <br />
            2. 회원은 "회사"의 전자메일을 통하여 음란물이나 명예훼손내용, 정크메일(junk mail), 스팸메일(spam mail), 행운의편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 메일, 외설 또는
            폭력적인 메시지 / 화상 / 음성 등 타인에게 피해를 주거나 미풍양속을 해치는 메일을 보내서는 안됩니다.
            <br />
            3. 본 조 제 2 항을 위반하여 발생되는 모든 책임은 회원에게 있으며, 이 경우 ID와 PASSWORD 등 개인정보를 수사기관에 제공할 수 있습니다.
            <br />
            4. SMS, 전화 등 기타 연락 수단도 제 10조 전항들의 내용을 준용합니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 12 조</strong> 회원 정보의 변경
          </dt>
          <dd>
            1. 회원은 이용신청 시 기재한 사항이 변경 되었을 경우, 즉시 해당사항의 수정을 "회사"에 요청하여야 합니다.
            <br />
            2. 회원정보가 변경되었음에도 해당사항의 수정을 요청하지 않음으로써 발생하는 각종 손해는 회원본인이 부담하여야 합니다. 다만, 회사의 고의나 과실에 의하여 손해가 발생한 경우에는 회사가 손해를
            부담합니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 13 조</strong> 쿠키(Cookie)의 운용 및 활용
          </dt>
          <dd>
            1. "회사"는 회원에게 적합하고 유용한 서비스를 제공하기위해서 회원의 정보를 저장하고 수시로 불러오는 쿠키(cookie)를 이용합니다.
            <br />
            2. 본 조 제 1 항과 관련하여 회원은 쿠키 이용에 대한 선택권을 가지며 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 이용하는 컴퓨터브라우저의 설정을 변경할 수 있습니다. 단, 쿠키의
            저장을 거부할 경우, 로그인이 필요한 모든 서비스를 이용할 수 없게 됨으로써 발생되는 문제에 대한 책임은 회원에게 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 14 조</strong> 링크 사이트에 대한 책임
          </dt>
          <dd>
            1. "회사"는 회원에게 다른 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 "회사"는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받은 서비스나 자료의 정확성,
            유용성 등에 대해 책임지지 아니하며 "회사"의 개인정보보호정책이 적용되지 않습니다.
            <br />
            2. 회원은 링크된 외부 사이트의 서비스나 자료를 신뢰함으로써 또는 이와 관련하여 발생하거나 발생되었다고 주장하는 어떠한 손해나 손실에 대해서도 "회사"에 책임이 없음을 인정하고 이에
            동의합니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 15 조</strong> 손해배상
          </dt>
          <dd>
            1. “회사”는 “회사”의 고의 또는 과실로 인하여 “회원”에게 손해가 발생한 경우에는 민법 등 관련 법령이 규율하는 범위 내에서 그 손해를 배상합니다.
            <br />
            2. “회원”이 이 약관의 의무를 위반함으로 인하여 “회사”에 손해를 입힌 경우 또는 “회원”이 “서비스”의 이용과 관련하여 “회사”에 손해를 입힌 경우 “회원”은 “회사”에 대하여 손해를 배상하여야
            합니다.
            <br />
            3. “회원”이 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한 행위로 “회사”가 당해 회원외의 제3자로부터 손해배상청구 또는 소송 등 각종이의제기를 받는 경우 당해 “회원”은 자신의
            책임과 비용으로 “회사”를 면책시켜야하며, “회사”가 면책되지못한 경우 당해 “회원”은 그로인하여 “회사”에 발생한 모든 손해를 배상할 책임이 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 16 조</strong> 면책
          </dt>
          <dd>
            "회사"는 다음 각 호의 사유로 인하여 회원에게 발생한 손해에 대해서는 책임지지 아니합니다. 다만, "회사"가 책임이 있다고 판단되는 상당한 인과관계가 있는 경우에는 그러하지 아니합니다.
            <br />
            <br />
            1. 통신기기, 회선 및 컴퓨터의 장애나 거래의 폭주 등 부득이한 사정으로 "서비스"가 제공되지 못하거나 지연된 경우
            <br />
            2. "회원"이 ID, PASSWORD 등을 본인의 관리 소홀로 인해 제3자에게 누출한 경우
            <br />
            3. "회원"의 전산조작이나 업무처리의 오류 등으로 인한 경우
            <br />
            4. 기타 천재지변 등의 불가피한 사유로 인한 경우
            <br />
            5. "회원"이 "회사"의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사 선택 또는 이용으로 손해가 발생한 경우
            <br />
            6. “회원”의 귀책 사유로 인하여 서비스 이용의 장애가 발생한 경우
            <br />
            7. 회원상호 간 또는 회원과 제3자상호간에 서비스를 매개로 하여 물품거래, 직거래 등을 한 경우
            <br />
            8. "회사"의 귀책사유없이 회원 간 또는 회원과 제3자간에 발생한 일체의 분쟁
            <br />
            9. 서버 등 설비의 관리, 점검, 보수, 교체 과정 또는 소프트웨어의 운용과정에서 고의 또는 고의에 준하는 중대한 과실없이 발생할 수있 는시스템의 장애, 제3자의 공격으로 인한 시스템의 장애,
            국내외의 저명한 연구기관이나 보안관련업체에 의해 대응 방법이 개발되지 아니한 컴퓨터 바이러스 등의 유포나 기타 "회사"가 통제할 수 없는 불가항력적 사유로 인해 "회원"에게 손해가 발생한 경우
            <br />
            10. 제9항의 회원의 손해에는 (1) 회원이 본 서비스를 이용하여 작성하고 있거나 전송 중이거나 저장한 게시물, 그에 첨부된 파일, 기타 데이터의 손상이나 손실 (이하 "데이터의 손상이나
            손실"이라합니다)로 인한 경제적, 정신적 손해 (2) 이와 같은 데이터의 손상이나 손실로 인하여 회원이 본 서비스를 이용하여 수행하고자한 사실행위 또는 제3자와의 계약 기타의 법률 행위 등이
            불가능해지거나 지연됨으로 인하여 회원에게 발생할 수 있는 경제적, 정신적 손해 등이 포함되며 이에 한정되지 않습니다.
            <br />
            11. 제9항 및 제10항에도 불구하고 "회사"가 제공하는 무료 서비스의 이용과 관련하여 "회사"의 고의 또는 중대한 과실없이 "회원"의 손해가 발생한 경우
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 17 조</strong> 저작권의 귀속 및 이용제한
          </dt>
          <dd>
            1. "회사"가 작성한 웹화면(문구 및 디자인)에 관한 저작권, 기타지적재산권은 "회사"에 귀속됩니다.
            <br />
            2. "회원"은 "회사"가 제공하는 서비스를 이용함으로써 얻은 정보를 "회사"의 사전승낙없이 출판, 복제, 방송 및 기타 방법에 의하여 유포하거나 제3자에게 이용하게해서는 아니됩니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 18 조</strong> 약관 위반 시 책임
          </dt>
          <dd>"회사"와 회원은 이 약관 및 동의서를 위반함으로써 발생하는 모든 책임을 각자 부담하며, 이로 인하여 상대방에게 손해를 입힌 경우에는 지체없이 배상하여야 합니다.</dd>
        </dl>
        <dl>
          <dt>
            <strong>제 19 조</strong> 서비스 중지
          </dt>
          <dd>
            1. "회사"는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 사전공지없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스 로의 교체 등 "회사"가 적절하다고 판단하는 사유에
            의하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.
            <br />
            2. "회사"는 서비스 설비의 장애 또는 서비스 이용의 폭주 등 "회사"의 귀책 사유없이 정상적인 서비스 제공이 불가능할 경우에는 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만, 이
            경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.
            <br />
            3. "회사"는 천재지변, 전쟁 등 불가항력적 사유가 발생한 경우 또는 기간통신사업자 등 전기통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지않는 경우에는 서비스를 중지할 수
            있습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 20 조</strong> 제휴사 또는 광고주와의 거래
          </dt>
          <dd>
            1. "회원"은 서비스 이용 시 노출되는 제휴사를 포함한 "회사"의 광고 게재에 대해 동의한 것으로 봅니다.
            <br />
            2. "회사"는 서비스 상에 게재되어있거나 본 서비스를 통한 제휴사 등의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 일체의 책임을 지지않습니다.
            <br />
            3. 회원은 서비스 내에 포함되어있는 링크를 통하여 다른웹사이트로 옮겨갈 경우, "회사"는 해당 사이트에서 제공하는 정보 내용 및 이로 인한 손해 등에 대한 책임을 지지않습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 21 조</strong> 회원에 대한 통지
          </dt>
          <dd>
            1. “회사”가 “회원”에 대한 통지를 하는 경우 본 약관에 별도규정이 없 는한 “회원”이 지정한 이메일, “서비스” 내 알림메시지 또는 전자게시물 등으로 할 수 있습니다.
            <br />
            2. “회사”는 “회원” 전체에 대한 통지의 경우 7일 이상 “회사”의 홈페이지 또는 앱서비스 공지사항 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 22 조</strong> 정보의 제공
          </dt>
          <dd>
            1. "회사"는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보 및 광고를 전자우편 또는 서신우편 등의 방법으로 회원에게 전송(또는제공)할 수 있으며, 회원은 이를 원하지 않을 경우
            "회원정보관리"에서 수신을 거부할 수 있습니다.
            <br />
            2. 전자우편의 수신을 거부한 경우에도 본약관(개별서비스이용약관포함), 개인정보보호정책, 기타중대한영업정책의 변경 등 회원이 반드시 알고있어야하는 공지사항이 있는 경우, 수신 거부와 관계없이
            공지메일을 발송할 수 있습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 23 조</strong> 이용료 등
          </dt>
          <dd>"회사"는 “회원”이 유료로 제공되는 서비스를 이용하는 경우, 이와 관련하여 각종 이용료를 "회원"에게 징수할 수 있습니다.</dd>
        </dl>
        <dl>
          <dt>
            <strong>제 24 조</strong> 분쟁조정 및 관할법원
          </dt>
          <dd>
            1. "회사" 및 "회원"은 회원정보에 관한 분쟁이 발생한 경우 신속하고 효과적인 분쟁해결을 위하여 정보통신망이용촉진 및 정보보호 등에 관한 법률의 규정에 의해 설치된 개인정보분쟁조정위원회에
            분쟁의 조정을 신청할 수있습니다.
            <br />
            2. 서비스 이용과 관련하여 발생한 분쟁에 대해 소송이 제기될 경우, “회사”의 본점소재지관할법원을 전속적 관할법원으로 합니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>제 25 조</strong> 관련법령과의 관계
          </dt>
          <dd>
            1. 이 약관 또는 개별약관에서 정하지않은 사항은 전기통신사업법, 전자거래기본법, 정보통신망이용촉진및정보보호 등에 관한법률, 전자상거래 등에서의 소비자보호에 관한법률 등 관련법령의 규정과
            일반적인 상관례에 의합니다.
            <br />
            2. “회원”은 “회사”가 제공하는 서비스를 이용함에 있어서 전자상거래 등에서의 소비자보호에 관한 법률, 전자거래기본법, 소비자보호법, 표시광고의 공정화에 관한 법률, 정보통신망이용촉진 및
            정보보호 등에 관한 법률 등 관련법령을 준수하여야하며, 이 약관의 규정을 들어 관련 법령위반에 대한 면책을 주장할 수 없습니다.
          </dd>
        </dl>
        <dl>
          <dt>
            <strong>[ 부칙 ]</strong> 본약관은 2017년 9월 1일부터적용됩니다
          </dt>
          <dd></dd>
        </dl>
      </div>
    </div>
    <FooterTemplate />
  </div>
)

export default PolicyTemplate
