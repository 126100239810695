import React from 'react'
import { O2_SAMPLE_REQUEST_URL } from 'constants/Constants'
import logo_o2_solution from 'static/images/common/o2_solution_logo.svg'
import './HeaderSection.scss'

const HeaderSection = () => (
  <div className="o2-header-section">
    <div className="o2-header-section__content">
      <img src={logo_o2_solution} className="" width={180} height={36} alt="오투 주소 솔루션" />
      <a href={O2_SAMPLE_REQUEST_URL} target="_blank" rel="noopener noreferrer" className="o2-header-section__inquery">
        문의/무료샘플
      </a>
    </div>
  </div>
)

export default HeaderSection
