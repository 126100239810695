import React from 'react'
import TwoColumnContent from '../TwoColumnContent/TwoColumnContent'
import './WhySection.scss'
import talk_capture from 'static/images/o2_solution/talk_capture@2x.png'

const WhySection = () => (
  <div className="why-section">
    <TwoColumnContent
      leftContent={
        <img
          src={talk_capture}
          alt=""
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: 400,
          }}
        />
      }
      rightContent={
        <div className="why-section__text">
          <h3>
            정보 <span>≠</span> 데이터
          </h3>
          <p>
            고객 정보와 배송 정보로 널리 사용되는 주소,
            <span className="br"></span>
            하지만 데이터로 사용되지 못하는 현실.
          </p>
          <p>
            방대한 데이터와의 연결고리인 주소를 활용하려면
            <span className="br"></span>
            전처리와 DB화가 필요합니다.
          </p>
        </div>
      }
    />
  </div>
)

export default WhySection
