import React from 'react'
import './StepSummarySection.scss'
import step_summary_1 from 'static/images/o2_solution/step_summary_1.svg'
import step_summary_2 from 'static/images/o2_solution/step_summary_2.svg'
import step_summary_3 from 'static/images/o2_solution/step_summary_3.svg'
import ico_step_arrow_right from 'static/images/o2_solution/icon_step_arrow_right.svg'

const sectionData = [
  {
    title: '기존주소 표준화',
    src: step_summary_1,
    descriptionTitle: '주거지 정보',
    descriptionTextContent: '면적/방수/평형/단지/가격 정보',
  },
  {
    title: '데이터 확충',
    src: step_summary_2,
    descriptionTitle: '주소 관련 정보',
    descriptionTextContent: '주변시설/지역/인구/통계 정보',
  },
  {
    title: '데이터 모델링',
    src: step_summary_3,
    descriptionTitle: '추론/예상 정보',
    descriptionTextContent: (
      <>
        통계적 설명력이 있는 상관관계
        <br />
        (ex. 구매/재구매/구매액/업세일)
      </>
    ),
  },
]

const StepSummarySection = () => {
  return (
    <ul className="step-summary-section">
      {sectionData.map((data, index) => (
        <li key={index} className="step-summary-section__item">
          {index !== 0 && <img src={ico_step_arrow_right} alt="다음 단계" className="step-summary-section__arrow" />}
          <span className="step-summary-section__step">{index + 1}단계</span>
          <div className="step-summary-section__title">{data.title}</div>
          <img src={data.src} width={192} height={192} alt="" />
          <div className="step-summary-section__description">
            <div className="step-summary-section__description-title">{data.descriptionTitle}</div>
            <div>{data.descriptionTextContent}</div>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default StepSummarySection
