import React from 'react'
import InqueryButton from '../InqueryButton/InqueryButton'
import './SloganSection.scss'

const SloganSection = () => {
  return (
    <div className="slogan-section">
      <p className="slogan-section__slogan">주소 정보를 디지털로 전환해보세요!</p>
      <p className="slogan-section__slogan-sub">무료로 샘플데이터를 받아보세요 (500건/ 영업일 3일이내)</p>
      <p>
        <InqueryButton />
      </p>
    </div>
  )
}

export default SloganSection
