import React from 'react'
import { Route, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './App.css'
import { Policy, Privacy, Introduction, O2Solution } from 'pages'

const browserHistory = createBrowserHistory()

const App = () => (
  <div className="App">
    <Router history={browserHistory}>
      <Route exact path="/" component={Introduction} />
      <Route path="/policy" component={Policy} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/o2solution" component={O2Solution} />
    </Router>
  </div>
)

export default App
