import React, { useEffect, useState } from 'react'
import partners_bg from 'static/images/introduction/partners@2x.png'
import partners_mobile_bg from 'static/images/introduction/partners-mobile@2x.png'
import gov_logo from 'static/images/introduction/gov-logo@2x.png'
import gov_phrase from 'static/images/introduction/gov_phrase@2x.png'
import './PartnerSection.scss'

const PartnerSection = () => {
  const [mobile, setMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    if (window.innerWidth > 768) {
      // if width > 768, mobile = false
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [])

  return (
    <div className="partner-section" id="partners">
      <p className="partner-section__title">PARTNERS</p>
      <p className="partner-section__subtitle">최고의 파트너들과 함께합니다.</p>
      <img src={mobile ? partners_mobile_bg : partners_bg} alt="bg" />
      <div className="partner-section__partners">
        <div className="partner">
          <div className="partner__name">
            디라이트 법무법인<p>Partner</p>
          </div>
          <p className="partner__introduce">
            AI-빅데이터-블록체인 등의 ICT, 엔터테인먼트, 헬스케어 분야 전문 로펌으로 스타트업 지원과 다양한 공익활동을 진행하고 있습니다. 2017년부터 집펀드의 파트너로 금융규제 및 준법, 개인정보,
            법률적 이슈, 계약에 관한 법률지원을 담당하고 있습니다.
          </p>
        </div>
        <div className="partner">
          <div className="partner__name">
            Jiho Lee<p>Partner</p>
          </div>
          <p className="partner__introduce">
            MIT대와 컬럼비아대 부동산 대학원에서 공부하였으며, 뉴욕 Tishman Capital Partners의 투자담당 이사이며 부동산 금융 겸임교수로 컬럼비아 대학에 출강중입니다. 집펀드 창업초기부터 비즈니스,
            서비스 개발 어드바이저로 활동하고 있습니다.
          </p>
        </div>
        <div className="partner">
          <div className="partner__name">
            June Choi<p>Partner</p>
          </div>
          <p className="partner__introduce">
            연세대학교와 하버드 대학교 부동산 대학원에서 공부하였으며, 한국 맥킨지(McKinsey & Company)에서 비지니스 컨설턴트로 근무하였습니다. 부동산 사모펀드에서의 경험을 바탕으로 2017년부터 집펀드의
            경영전략 및 부동산과 IT 전략을 돕고 있습니다.
          </p>
        </div>
      </div>
      <div className="government-designated">
        <img className="gov-logo" src={gov_logo} alt="" />
        <img className="gov-phrase" src={gov_phrase} alt="" />
      </div>
    </div>
  )
}

export default PartnerSection
