import React from 'react'
import logoZipfund from 'static/images/common/logo-zipfund.svg'
import iconSocialNaver from 'static/images/common/icons/social-naver.svg'
import iconSocialInstagram from 'static/images/common/icons/social-instagram.svg'
import './FooterTemplate.scss'

const FooterTemplate = () => {
  const socialLinks = [
    {
      icon: iconSocialNaver,
      text: '네이버',
      url: 'https://blog.naver.com/zipadvisor',
    },
    {
      icon: iconSocialInstagram,
      text: '인스타그램',
      url: 'https://www.instagram.com/zipfund/',
    },
  ]

  return (
    <footer className="footer-section">
      <div className="footer-section__container">
        <p className="footer-section__links">
          <img src={logoZipfund} width={138.82} height={27} alt="ZIPFUND" />
          <span className="footer-section__socials">
            {socialLinks.map((item, index) => (
              <a key={index} href={item.url}>
                <img src={item.icon} alt={item.text} />
              </a>
            ))}
          </span>
        </p>
        <p className="footer-section__title">(주)집펀드</p>
        <div className="footer-section__info">
          <p className="footer-section__reg-no">사업자 등록번호 : 286-86-00253 | 대표 : 남성태</p>
          <div className="footer-section__address">
            <p className="footer-section__address-1">주소 : 서울 영등포구 의사당대로 83 오투타워 6층 107호</p>
            <p className="footer-section__address-2">
              <a
                href=" https://www.google.co.kr/maps/place/%EC%98%A4%ED%88%AC%ED%83%80%EC%9B%8C/data=!3m1!4b1!4m5!3m4!1s0x357c9f98f7554dfb:0x601608f1f9a43df!8m2!3d37.5233278!4d126.9234386?hl=ko"
                className="footer-section__address-map"
              >
                구글 지도
              </a>
            </p>
          </div>
        </div>
        <p className="footer-section__link-item">
          <span>E-mail</span>
          zipman@zipfund.co
        </p>
        <p className="footer-section__link-item">
          <span>Tel</span>
          02-553-6369
        </p>
        <p>
          <span className="footer-section__link-fax">Fax</span>
          02-6280-6369
        </p>
        <p className="footer-section__terms">
          <a href="https://zipadvisor.co/policy">이용약관</a>
          <a href="https://zipadvisor.co/privacy">개인정보취급방침</a>
        </p>
        <p className="footer-section__copyright">©zipfund Co., Ltd. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default FooterTemplate
