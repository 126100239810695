const history = [
  {
    date: '2020.06.',
    contents: '과학기술정보통신부  2020년도 민관 협력 기반 ICT 스타트업 육성 사업 선정 (5억원)',
  },
  {
    date: '2020.04.',
    contents: '금융위원회-핀테크지원센터 2020 금융규제 테스트베드 지원 선정',
  },
  {
    date: '2019.08.',
    contents: '대한민국 리딩기업대상 서비스혁신대상 (프롭테크 부문)',
  },
  {
    date: '2019.06.',
    contents: '중소벤처기업부 혁신형 창업과제 선정 (기술기반 창업형 4억원)',
  },
  {
    date: '2019.06.',
    contents: '금융위원회-핀테크지원센터 2019 금융규제 테스트베드 지원 선정',
  },
  {
    date: '2019.05.',
    contents: '담보대출심사 국내최초 위수탁 계약 체결 (SBI 저축은행)',
  },
  {
    date: '2019.05.',
    contents: "세계최초 AI 부동산비서 '집비서' 서비스 정식 런칭",
  },
  {
    date: '2018.11.',
    contents: "홍콩 프롭테크사 'FONTO HOLDINGS'와 자동가치평가모델(AVM) 기술관련 MOU 체결",
  },
  {
    date: '2018.11.',
    contents: '1차 투자유치 (SJ투자파트너스, 우미건설)',
  },
  {
    date: '2018.09.',
    contents: 'KT 정식 비즈니스 파트너쉽 체결 (인공지능 스피커)',
  },
  {
    date: '2018.09.',
    contents: '금융위원회 선정 1차 지정대리인 지정',
  },
  {
    date: '2018.08.',
    contents: "한화 금융그룹 핀테크 지원 프로그램 '드림플러스 63' 선정",
  },
  {
    date: '2018.07.',
    contents: "KT '기가지니' 공모전 최우수상 수상 (AI부동산 비서 '집어드바이저')",
  },
  {
    date: '2018.05.',
    contents: 'KISA(인터넷 진흥원) 핀테크 API개발 지원 사업 선정',
  },
  {
    date: '2017.11.',
    contents: '은행권청년창업재단 D-Angel 프로그램 선정 및 디캠프 입주',
  },
  {
    date: '2017.10.',
    contents: '전국아파트동호수 DB상용화 및 국내 최초 부동산 로보어드바이저 서비스 베타출시',
  },
  {
    date: '2017.05.',
    contents: "데이터 진흥원 'DB-Stars' 4기 선정",
  },
  {
    date: '2016.12.',
    contents: "미래창조과학부 'K Global 300' 기업 선정",
  },
  {
    date: '2016.04.',
    contents: '한국 핀테크산업협회 정회원 등록',
  },
  {
    date: '2015.12.',
    contents: '주식회사 집펀드 설립 (부동산 P2P 금융 서비스)',
  },
  {
    date: '2015.08.',
    contents: '온누리소셜벤처우수상 수상 (부동산 P2P 서비스)',
  },
  {
    date: '2015.07.',
    contents: "'본투글로벌 2015' 멤버사선정",
  },
  {
    date: '2014.09.',
    contents: '유한책임회사 트리플넷소프트 설립 (소프트웨어 개발)',
  },
]

export default history
