import React from 'react'
import FooterTemplate from 'components/footer/FooterTemplate'
import HeaderSection from '../HeaderSection/HeaderSection'
import BannerSection from '../BannerSection/BannerSection'
import WhySection from '../WhySection/WhySection'
import WhatTheySaySection from '../WhatTheySaySection/WhatTheySaySection'
import StepSection from '../StepSection/StepSection'
import StepSummarySection from '../StepSummarySection/StepSummarySection'
import SloganSection from '../SloganSection/SloganSection'
import './O2SolutionTemplate.scss'

const O2SolutionTemplate = () => (
  <div className="o2solution-template">
    <HeaderSection />
    <BannerSection />
    <WhySection />
    <WhatTheySaySection />
    <StepSection />
    <StepSummarySection />
    <SloganSection />
    <FooterTemplate />
  </div>
)

export default O2SolutionTemplate
