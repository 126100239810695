import React from 'react'
import logo_zipbiseo from 'static/images/common/zipbiseo-logo.svg'
import logo_zipdata from 'static/images/common/zipdata-logo@2x.png'
import logo_o2_solution from 'static/images/common/o2_solution_logo.svg'
import blue_arrow from 'static/images/introduction/arrow-blue@2x.png'
import './ServiceSection.scss'

const ServiceSection = () => (
  <div className="service-section" id="service">
    <p className="service-section__title">SERVICE</p>
    <div className="service-list">
      <div className="service-list__menu">
        <img src={logo_zipbiseo} className="menu__title" alt="zipadvisor logo" />
        <p className="menu__explanation">
          부동산 빅데이터와 개인의 금융 데이터를 활용한
          <br />
          맞춤형 자산관리(PFM) 서비스 입니다.
          <br />
          <br />
        </p>
        <a className="menu__link--button" href={'http://zipadvisor.co'}>
          <p>자세히 알아보기</p>
          <img src={blue_arrow} alt="" />
        </a>
      </div>
      <div className="service-list__menu">
        <img src={logo_o2_solution} className="menu__title" alt="오투 주소 솔루션" />
        <p className="menu__explanation">
          고객의 주소 정보를 디지털로 전환하여
          <br />
          데이터를 추가하고 입체적인 고객 분석과
          <br />
          마케팅을 지원하는 서비스 입니다.
        </p>
        <a className="menu__link--button" href={'/o2solution'}>
          <p>자세히 알아보기</p>
          <img src={blue_arrow} alt="" />
        </a>
      </div>
      <div className="service-list__menu">
        <img src={logo_zipdata} className="menu__title" alt="zipdata logo" />
        <p className="menu__explanation">
          금융위원회로부터 지정대리인 자격을 부여받은
          <br />
          B2B 아파트 담보 대출 심사 자동화
          <br />
          솔루션 서비스 입니다.
        </p>
        <a className="menu__link--button" href={'https://zipdata.co'}>
          <p>서비스 바로가기</p>
          <img src={blue_arrow} alt="" />
        </a>
      </div>
    </div>
  </div>
)

export default ServiceSection
