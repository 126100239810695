import React from 'react'
import { O2_SAMPLE_REQUEST_URL } from 'constants/Constants'
import './InqueryButton.scss'

const InqueryButton = () => {
  return (
    <a href={O2_SAMPLE_REQUEST_URL} target="_blank" rel="noopener noreferrer" className="inquery-button">
      문의 및 무료샘플요청
    </a>
  )
}

export default InqueryButton
