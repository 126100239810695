import React, { useState } from 'react'
import history from 'constants/History'
import './StorySection.scss'

const StorySection = () => {
  const [more, setMore] = useState(false)
  const renderHistory = () => {
    return history.map((episode, idx) => {
      if (more) {
        return (
          <div className="story">
            <p className="story__date">{episode.date}</p>
            <p className="story__contents">{episode.contents}</p>
          </div>
        )
      } else {
        return (
          idx < 7 && (
            <div className="story">
              <p className="story__date">{episode.date}</p>
              <p className="story__contents">{episode.contents}</p>
            </div>
          )
        )
      }
    })
  }
  return (
    <div className="story-section" id="history">
      <p className="story-section__title">HISTORY</p>
      <div className="story-section__stories">
        {renderHistory()}
        <div className="more" onClick={() => setMore(!more)}>
          {more ? '접기' : '더보기'}
        </div>
      </div>
    </div>
  )
}

export default StorySection
