import React, { useEffect, useState } from 'react'
import './InterviewSection.scss'

const InterviewSection = () => {
  const [mobile, setMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    if (window.innerWidth > 768) {
      // if width > 768, mobile = false
      setMobile(false)
    } else {
      setMobile(true)
    }
  }, [])

  return (
    <div className="interview-section" id="zipfund">
      <div className="about-section">
        <p className="about-section__title">{mobile ? 'ABOUT' : 'ABOUT ZIPFUND'}</p>
        <p className="about-section__subtitle">고객들의 현명한 자산관리를 위해 노력합니다.</p>
        <p className="about-section__contents">집펀드는 수년간 축적한 부동산 빅데이터를 과학적으로 분석해 정보의 비대칭을 해소하고 부동산 개인자산관리(PFM) 솔루션을 제공하는 프롭테크 기업입니다.</p>
      </div>
    </div>
  )
}

export default InterviewSection
