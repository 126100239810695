import React from 'react'
import './PressSection.scss'

const newsList = [
  {
    source: '이투뉴스',
    dateStr: '2022/12/01',
    title: `프롭테크 스타트업 집펀드, ‘집비서’ 서비스 출시`,
    href: 'http://www.e2news.com/news/articleView.html?idxno=248282',
  },
  {
    source: '비지니스코리아',
    dateStr: '2022/11/15',
    title: `집비서, 고금리 시대에 적합한 부동산 자산관리 서비스 출시`,
    href: 'http://www.businesskorea.co.kr/news/articleView.html?idxno=104123',
  },
  {
    source: '지디넷코리아',
    dateStr: '2022/07/28',
    title: `원희룡 국토부 장관, 국토교통 신산업 ‘스타트업 소통 릴레이’`,
    href: 'https://zdnet.co.kr/view/?no=20220728115853',
  },
  {
    source: '신아일보',
    dateStr: '2022/06/08',
    title: `[창간특집] 변두리서 대세로…부동산+기술 '프롭테크' 산업 혁신 가속화 이끈다`,
    href: 'http://www.shinailbo.co.kr/news/articleView.html?idxno=1559128',
  },
  {
    source: '머니투데이',
    dateStr: '2020/08/14',
    title: `'2020 제6회 대한민국 리딩기업대상'에서 '2년 연속상'으로 '프롭테크' 부문 'DNA(Data-network-AI) 혁신상' 수상`,
    href: 'https://news.mt.co.kr/mtview.php?no=2020081311114356201',
  },
  {
    source: '비즈니스위치',
    dateStr: '2019/05/20',
    title: `"우리집, 언제 팔면 최고가?"`,
    href: 'http://news.bizwatch.co.kr/article/mobile/2019/05/20/0020',
  },
  {
    source: '서울경제',
    dateStr: '2019/01/27',
    title: `남성태 집펀드 대표 "빅데이터 기반 아파트 자산관리 시장 커질 것"`,
    href: 'https://sedaily.com/NewsView/1VE79FRMNX',
  },
  {
    source: '일간투데이',
    dateStr: '2019/01/17',
    title: `4차산업혁명, 현장 사령탑에게 듣다, 집펀드 대표 인터뷰`,
    href: 'http://www.dtoday.co.kr/news/articleView.html?idxno=293302',
  },
]

const PressSection = () => {
  return (
    <div className="press-section" id="press">
      <div className="press-section__title">PRESS</div>
      <div className="press-section__lists">
        {newsList.map((item, index) => (
          <div key={index} className="lists__news">
            <div>
              <p className="news-company">{item.source}</p>
              <p> | </p>
              <p className="news-date">{item.dateStr}</p>
            </div>
            <a className="news__title" href={item.href} target="_blank" rel="noopener noreferrer">
              {item.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PressSection
