import React, { useState, useEffect } from 'react'
import logo from 'static/images/common/zipfund-logo.png'
import logo_white from 'static/images/common/png_logo_zipfund_white.png'
import './Header.scss'

const Header = () => {
  // const [ scrollTop, setScrollTop ] = useState(0);
  const [aboveContent, setAboveContent] = useState(document.scrollingElement.scrollTop < 1)
  const [mobile, setMobile] = useState(window.innerWidth < 768)

  /** mouse scroll event */
  const onScroll = (e) => {
    let scrollTop = e.target.scrollingElement.scrollTop
    if (scrollTop > document.querySelector('.top-section').clientHeight - 1) {
      setAboveContent(true)
    } else {
      setAboveContent(false)
    }
  }

  const clickMenu = (e) => {
    let supports = false

    try {
      window.scrollTo({
        top: 0,
        get behavior() {
          supports = true
          return 'smooth'
        },
      })
    } catch (err) {
      console.log(err)
    }
    console.log('[log] supports scrollTo behavior : ' + supports)

    if (supports) {
      window.scrollTo({
        behavior: 'smooth',
        top: document.getElementById(e.currentTarget.getAttributeNode('_to').value).offsetTop + 645, // 720(top section) - 75(header height)
      })
    } else {
      window.scrollTo(document.getElementById(e.currentTarget.getAttributeNode('_to').value).offsetLeft, document.getElementById(e.currentTarget.getAttributeNode('_to').value).offsetTop + 645)
    }
  }

  useEffect(() => {
    setAboveContent(document.querySelector('.top-section').clientHeight > 719)
    if (window.innerWidth > 768) {
      // if width > 768, mobile = false
      setMobile(false)
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      // clean up effect
      console.log('cleanup')
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  if (mobile) {
    return (
      <div className={aboveContent ? 'header-section above-content' : 'header-section'}>
        <div className="header__logo">
          <button onClick={() => window.location.reload()}>
            <img src={logo} alt="logo" />
          </button>
        </div>
        <div className="header__menu-container">
          <button onClick={() => window.location.reload()} className="header__menu">
            회사소개
          </button>
          <a href="http://zipadvisor.co" className="header__menu">
            집비서
          </a>
          {/*<Link to="/zipdata" className="header__menu">집데이터</Link>*/}
        </div>
      </div>
    )
  } else {
    return (
      <div className={aboveContent ? 'header-section above-content' : 'header-section'}>
        <div className="header__logo">
          <button onClick={() => window.location.reload()}>
            <img src={aboveContent ? logo : logo_white} alt="logo" />
          </button>
        </div>
        <div className="header__menu-container">
          <button _to="zipfund" className="header__menu" onClick={clickMenu}>
            ABOUT
          </button>
          <button _to="service" className="header__menu" onClick={clickMenu}>
            SERVICE
          </button>
          <button _to="partners" className="header__menu" onClick={clickMenu}>
            PARTNERS
          </button>
          <button _to="history" className="header__menu" onClick={clickMenu}>
            HISTORY
          </button>
          <button _to="awards" className="header__menu" onClick={clickMenu}>
            AWARDS
          </button>
          <button _to="press" className="header__menu" onClick={clickMenu}>
            PRESS
          </button>
          <button _to="contact" className="header__menu" onClick={clickMenu}>
            CONTACT
          </button>
        </div>
      </div>
    )
  }
}

export default Header
