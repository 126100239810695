import React from 'react'
import Header from 'components/header'
import SectionTop from '../SectionTop'
import InterviewSection from '../InterviewSection'
import ServiceSection from '../ServiceSection'
import PartnerSection from '../PartnerSection'
import StorySection from '../StorySection'
import AwardSection from '../AwardSection'
import PressSection from '../PressSection'
import BannerSection from '../BannerSection'
import MapSection from '../MapSection'
import FooterTemplate from 'components/footer/FooterTemplate'
import './IntroductionTemplate.scss'

const IntroductionTemplate = () => (
  <div className="introduction-template">
    <Header />
    <SectionTop />
    <div className="fixed-templates">
      <InterviewSection />
      <ServiceSection />
      <PartnerSection />
      <StorySection />
      <AwardSection />
      <PressSection />
      <BannerSection />
      <MapSection />
      <FooterTemplate />
    </div>
  </div>
)

export default IntroductionTemplate
