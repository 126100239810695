import React, { useEffect, useState } from 'react'
import map_bg from 'static/images/introduction/map_desktop.png'
import map_mobile_bg from 'static/images/introduction/map_mobile.png'
import './MapSection.scss'

const MapSection = () => {
  const [mobile, setMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    if (window.innerWidth > 768)
      // if width > 768, mobile = false
      setMobile(false)
    else setMobile(true)
  }, [])

  return (
    <div className="map-section" id="contact">
      <p className="map-section__title">CONTACT</p>
      {mobile ? (
        <>
          <img src={map_mobile_bg} alt="" />
          <div className="company-info">
            <p className="company-info__location">
              (주)집펀드
              <br /> 서울특별시 영등포구 의사당대로 83, 6층 107호 (여의도동, 오투타워)
            </p>
            <p className="company-info__contact">
              E-mail : zipman@zipfund.co
              <br />
              Tel : 02-553-6369 | Fax : 02-6280-6369
            </p>
          </div>
        </>
      ) : (
        <>
          <img src={map_bg} alt="" />
          <div className="company-info">
            <p className="company-info__location">(주)집펀드 | 서울특별시 영등포구 의사당대로 83, 6층 107호 (여의도동, 오투타워)</p>
            <p className="company-info__contact">E-mail : zipman@zipfund.co | Tel : 02-553-6369 | Fax : 02-6280-6369</p>
          </div>
        </>
      )}
    </div>
  )
}

export default MapSection
