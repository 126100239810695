import React from 'react'
import './AwardSection.scss'

const AwardSection = () => {
  return (
    <div className="award-section" id="awards">
      <p className="award-section__title">AWARDS</p>
      <div className="award-section__awards">
        <div className="award">
          <p className="award__date">2020.08.</p>
          <p className="award__contents">대한민국 리딩기업대상 서비스혁신대상 2회연속 수상 (DNA(Data-network-AI) 혁신상)</p>
        </div>
        <div className="award">
          <p className="award__date">2019.06.</p>
          <p className="award__contents">중소벤처기업부 혁신형 창업과제 선정 (기술기반 창업형 4억원)</p>
        </div>
        <div className="award">
          <p className="award__date">2019.06.</p>
          <p className="award__contents">금융위원회-핀테크지원센터 금융규제 테스트베드 지원 선정</p>
        </div>
        <div className="award">
          <p className="award__date">2018.09.</p>
          <p className="award__contents">경기창조경제혁신센터 비즈콜라보 선정</p>
        </div>
        <div className="award">
          <p className="award__date">2018.08.</p>
          <p className="award__contents">한화 금융그룹 핀테크 지원 프로그램 '드림플러스 63' 선정</p>
        </div>
        <div className="award">
          <p className="award__date">2018.07.</p>
          <p className="award__contents">KT '기가지니' 공모전 최우수상 수상 (AI부동산 비서 '집어드바이저')</p>
        </div>
        <div className="award">
          <p className="award__date">2018.05.</p>
          <p className="award__contents">KISA(인터넷 진흥원) 핀테크 API개발 지원 사업 선정</p>
        </div>
        <div className="award">
          <p className="award__date">2017.11.</p>
          <p className="award__contents">은행권청년창업재단 D-Angel 프로그램 선정 및 디캠프 입주</p>
        </div>
        <div className="award">
          <p className="award__date">2017.05.</p>
          <p className="award__contents">데이터 진흥원 'DB-Stars' 4기 선정</p>
        </div>
        <div className="award">
          <p className="award__date">2016.12.</p>
          <p className="award__contents">미래창조과학부 'K Global 300' 기업 선정</p>
        </div>
        <div className="award">
          <p className="award__date">2015.08.</p>
          <p className="award__contents">온누리소셜벤처우수상 수상 (부동산 P2P 서비스)</p>
        </div>
        <div className="award">
          <p className="award__date">2015.07.</p>
          <p className="award__contents">‘본투글로벌 2015’ 멤버사선정</p>
        </div>
      </div>
    </div>
  )
}

export default AwardSection
