import React from 'react'
import cx from 'classnames'
import './TwoColumnContent.scss'

const TwoColumnContent = ({ leftContent, rightContent, reverseOrder = false, alignItems = 'center' }) => {
  const contentCx = cx('two-column-content', {
    'two-column-content--align-start': alignItems === 'start',
  })
  const firstItemCx = cx({
    'two-column-content__item--reverse': reverseOrder,
  })
  return (
    <div className={contentCx}>
      <div className={firstItemCx}>{leftContent}</div>
      <div className="two-column-content__right">{rightContent}</div>
    </div>
  )
}

export default TwoColumnContent
