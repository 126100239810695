import React from 'react'
import './SectionTop.scss'

const SectionTop = () => (
  <div className="top-section first">
    <div className="introduction__container">
      <p className="introduction__title">
        We serve clients
        <br />
        by creating values with data
      </p>
      <a href="https://youtu.be/S5i2ncZzAB4">
        <button className="video__button">
          <p>집펀드 더 알아보기</p>
        </button>
      </a>
    </div>
  </div>
)

export default SectionTop
